export default {
  BUTTON_PRIMARY_CLASS: 'cm_button cm_button__primary',
  BUTTON_SECONDARY_CLASS: 'cm_button cm_button__secondary',
  SEARCH_BOX_BUTTON_CLASS: 'cm_button cm_button__primary',
  SELECT_CLASS: 'cm_select__pretty select2-untouched',
  PRODUCT_REPEATER_CLASS: 'products list items product-items',
  TABS_CONTENT_CLASS: 'cm_tab-content active',

  CLEAR_TEXT: '<rt-virtual template="icons/reset" />',
  SEARCH_BOX_PLACEHOLDER: 'Search by VIN / Part# / Keyword',
  FITMENT_TABLE_VEHICLE_SPECIFIC: 'This products fits:',
};
